export const defaults = {
    page_title: "Молдова 24. Мы говорим о том, что важно.",
    main_url: "https://tracker.md24-telegram.com/tracking202/redirect/go.php?acip=317",
    name: "Молдова 24. Мы говорим о том, что важно.",
    tag: "@moldovanews24",
    main_avatar_url: "/img/md24.jpg",
    parsers:[
        {
            name: "MD24",
            parser: "MD24",
            tag: "@moldovanews24",
            main_url: "https://tracker.md24-telegram.com/tracking202/redirect/go.php?acip=317",
            avatar_url: "/img/md24.jpg",
            contacts_phrase: "Непрочитанные сообщения",
            new_messages: 7
        },
    ],
    posts: []
}
